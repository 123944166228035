import { motion } from "framer-motion";
import { cn } from "lib/utils";
import { ANIMATED_CONTAINER } from "../../lib/utils";

export default function Section({ wrapperClassName, className, id, children, animate = false }) {
  if (animate) {
    return (
      <motion.section
        id={id}
        className={cn(wrapperClassName)}
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.4 }}
        variants={ANIMATED_CONTAINER}
      >
        <div className={cn("content", className)}>{children}</div>
      </motion.section>
    );
  }
  return (
    <section id={id} className={cn(wrapperClassName)}>
      <div className={cn("content", className)}>{children}</div>
    </section>
  );
}
