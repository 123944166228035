import React from "react";
import ReactPlayer from "react-player";

import { Image } from "@/components";
import { Play } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/ui/dialog";

export default function Video({ image, url, title }) {
  return (
    <>
      <Dialog>
        <DialogTrigger>
          <div className="relative rounded-lg overflow-hidden group my-6">
            <Image
              src={image}
              alt="Thumbnail for Video"
              className="w-full h-[280px] md:h-[360px] lg:h-[440px]"
              width={780}
              height={440}
            />
            <div className="absolute w-full h-full top-0 left-0 bg-black/50 flex items-center justify-center group-hover:scale-105 transition-all duration-200">
              <div className="rounded-full px-3 py-2 flex items-center gap-2 text-white bg-white/40 font-semibold">
                <div className="flex justify-center items-center rounded-full border border-white w-8 h-8">
                  <Play className="w-4 h-4 text-current" />
                </div>
                Watch Video
              </div>
            </div>
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-3xl max-h-[80svh] overflow-auto p-4">
          <DialogHeader>
            <DialogTitle className="h3 font-medium mb-2 line-clamp-1 !pr-8">{title}</DialogTitle>
          </DialogHeader>
          <div className="rounded-lg overflow-hidden h-[240px] md:h-[404px]">
            <ReactPlayer url={url} className="w-full [&>iframe]:w-full " width="100%" height="100%" controls />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
