export { default as AdminIcon } from "./admin/admin.svg";
export { default as CloseIcon } from "./admin/close.svg";
export { default as CloudinaryIcon } from "./admin/cloudinary.svg";
export { default as FigmaIcon } from "./admin/figma.svg";
export { default as GithubAdminIcon } from "./admin/github.svg";
export { default as GoogleSearchConsoleIcon } from "./admin/google-search-console.svg";
export { default as GoogleTagManagerIcon } from "./admin/google-tag-manager.svg";
export { default as HubspotIcon } from "./admin/hubspot.svg";
export { default as CalendlyIcon } from "./admin/calendly.svg";
export { default as TinaIcon } from "./admin/tina.svg";
export { default as VercelAnalyticsIcon } from "./admin/vercel-analytics.svg";
export { default as VercelIcon } from "./admin/vercel.svg";
export { default as KoalaIcon } from "./admin/koala.svg";
export { default as GoogleAnalyticsIcon } from "./admin/google-analytics.svg";
export { default as LighthouseIcon } from "./admin/lighthouse.svg";
export { default as ClinikoIcon } from "./admin/cliniko.svg";
export { default as DoctifyIcon } from "./admin/doctify.svg";
export { default as GoogleDriveIcon } from "./admin/google-drive.svg";

export { default as Logo } from "./brand/logo.svg";
export { default as LogoEye } from "./brand/logo-eye.svg";

export { default as Arrow } from "./icons/arrow.svg";
