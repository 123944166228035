import NextImage from "next/image";
import { cn } from "@/utils";

const Image = ({ src, alt, className, objectFit = "cover", objectPosition = "center", bg, ...props }) => {
  const img = src;

  return (
    <div className={cn("relative overflow-hidden", className)}>
      <div className="w-full h-full overflow-hidden">
        {img ? (
          <NextImage
            src={img}
            alt={alt}
            // fill
            // layout="fill"
            // objectFit={objectFit}
            // objectPosition={objectPosition}
            fill={props.width && props.height ? false : true}
            className={cn("relative block overflow-hidden object-cover", className)}
            quality={100}
            {...props}
          />
        ) : (
          <div className={cn("w-full h-full", bg ? bg : "bg-gray-300")}></div>
        )}
      </div>
    </div>
  );
};

export default Image;
