// import dynamic from "next/dynamic";
import { useState, useEffect, useLayoutEffect } from "react";
import Link from "next/link";
import { useWindowSize } from "@uidotdev/usehooks";

import { motion, AnimatePresence } from "framer-motion";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/ui/tooltip";

import { Mail, MessageCircle, MessageSquareText, Phone, X } from "lucide-react";
import { useRouter } from "next/router";

const iconMap = {
  whatsapp: <MessageCircle className="w-6 h-6" />,
  message: <MessageSquareText className="w-6 h-6" />,
  phone: <Phone className="w-6 h-6" />,
  email: <Mail className="w-6 h-6" />,
};

export default function SpeedDial({ globalData }) {
  const contact = globalData?.contact;

  const [isOpen, setIsOpen] = useState(false);
  let buttons = [];

  if (contact?.phone) {
    buttons.push({
      label: "Call us",
      url: contact.phone,
      icon: iconMap.phone,
    });
  }

  if (contact?.email) {
    buttons.push({
      label: "Email Us",
      url: contact.email,
      icon: iconMap.email,
    });
  }

  buttons.push({
    label: "Online Contact Form",
    url: "/contact",
    icon: iconMap.message,
  });

  if (contact?.whatsapp) {
    buttons.push({
      label: "Message us on WhatsApp",
      url: contact.whatsapp,
      icon: iconMap.whatsapp,
    });
  }

  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: (buttons.length - index - 1) * 0.05, // Reverse staggered effect
        duration: 0.2,
      },
    }),
    exit: {
      opacity: 0,
      y: 40,
      transition: { duration: 0.2 },
    },
  };

  const MotionLink = motion.create(Link);

  const size = useWindowSize();
  const location = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  }, [location]);

  return (
    <div className="fixed bottom-2 right-2 lg:bottom-4 lg:right-4 flex flex-col gap-2 z-10">
      <AnimatePresence>
        {isOpen && (
          <motion.div initial="hidden" animate="visible" exit="exit" className="flex flex-col gap-3 relative z-10">
            {buttons.reverse().map(({ icon, label, url }, index) => (
              <TooltipProvider key={label} delayDuration={0}>
                <Tooltip defaultOpen={size.width < 768}>
                  <TooltipTrigger>
                    {url.startsWith("http") ? (
                      <motion.div
                        custom={index}
                        variants={buttonVariants}
                        className="w-14 h-14 bg-white border border-black/30 text-primary hover:bg-primary hover:text-white transition-colors duration-300 rounded-full flex justify-center items-center"
                        onClick={() => window.open(url, "_blank")}
                        aria-label={label}
                      >
                        {icon}
                      </motion.div>
                    ) : (
                      <MotionLink
                        custom={index}
                        variants={buttonVariants}
                        href={url}
                        className="w-14 h-14 bg-white border border-black/30 text-primary hover:bg-primary hover:text-white transition-colors duration-300 rounded-full flex justify-center items-center"
                        aria-label={label}
                      >
                        {icon}
                      </MotionLink>
                    )}
                  </TooltipTrigger>
                  <TooltipContent side="left" sideOffset={10}>
                    {label}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger>
            <div
              className="w-14 h-14 bg-primary border border-white hover:bg-primary-400 transition-colors duration-300 rounded-full flex justify-center items-center relative z-20"
              onClick={() => setIsOpen(!isOpen)}
              aria-label="Get in Touch"
            >
              {isOpen ? <X className="w-7 h-7 text-white" /> : <Phone className="w-6 h-6 text-white" />}
            </div>
          </TooltipTrigger>
          <TooltipContent side="left" sideOffset={10}>
            Get in Touch
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
