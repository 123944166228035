import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { cn } from "@/lib/utils";

import { AnimatePresence, motion } from "framer-motion";
import { setCookie, getCookie, hasCookie } from "cookies-next";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { Button } from "../ui/button";
import { CircleCheckBig } from "lucide-react";

import { Analytics } from "@vercel/analytics/react";

export default function Tracking() {
  const router = useRouter();
  const [hasConsent, setHasConsent] = useState();

  useEffect(() => {
    setHasConsent(hasCookie("cookie_notice_accepted") ? getCookie("cookie_notice_accepted") : null);
  }, []);

  const giveConsent = (answer) => {
    setHasConsent(answer);
    var CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 1);
    setCookie("cookie_notice_accepted", answer, { expires: CookieDate });
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (hasConsent === null) {
        giveConsent(true);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [hasConsent, router.events]);

  return (
    <div>
      <AnimatePresence>
        {hasConsent === null && (
          <motion.div
            className="fixed bottom-2 lg:bottom-4 left-2 lg:left-4 z-[60]"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.3 }}
          >
            <div
              className={cn(
                "pl-6 pr-4 py-3 lg:pr-8 lg:pl-4 lg:py-3 bg-primary text-white border border-primary rounded-full flex justify-between gap-3 lg:gap-4 scale-[0.75] lg:scale-100 origin-bottom-left"
              )}
            >
              <button
                className="group transition-all duration-500"
                onClick={() => {
                  giveConsent(true);
                }}
                aria-label="Accept cookies"
              >
                <CircleCheckBig className="w-10 h-10 group-hover:[&>path]:stroke-green-400" />
              </button>
              <div className="flex flex-col items-start gap-1 lg:gap-0.5 max-w-[300px]">
                <p className="text-base">This website uses cookies</p>
                <div className="text-sm text-light">
                  By clicking the tick or navigating you accept.
                  <Button
                    variant="linkLight"
                    size="xs"
                    onClick={() => giveConsent(false)}
                    className="mt-1 underline"
                    aria-label="That's Ok"
                  >
                    That's Ok
                  </Button>
                  <Button
                    variant="linkLight"
                    size="xs"
                    onClick={() => giveConsent(false)}
                    className="mt-1 ml-4 underline opacity-70"
                    aria-label="I wish to opt out"
                  >
                    I wish to opt out
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {process.env.NODE_ENV !== "development" && <Analytics />}
      {hasConsent && process.env.NODE_ENV !== "development" && (
        <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID} />
      )}
    </div>
  );
}
