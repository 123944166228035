import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import axios from "axios";

import { useEffect, useState } from "react";
import { tinaField as TinaField, useTina as useTinaHook } from "tinacms/dist/react";
import { client } from "../tina/__generated__/client";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const tinaClient = client;
export const useTina = useTinaHook;
export const tinaField = TinaField;

export const fetchReviews = async () => {
  try {
    const response = await axios.get(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/reviews`);
    return response.data.socialReviews;
  } catch (e) {
    console.error("Error fetching social reviews:", e);
  }
};

export const useGetCollection = (collection) => {
  const collectionEdges = collection?.edges;

  let collectionToShow;
  // const isEditing = true;
  const isEditing = useIsEditing();

  // Admin would still be able to do the call, but only show drafts when in edit mode or /admin
  if (!isEditing) {
    collectionToShow = collectionEdges?.filter((post) => post.node.published);
  } else {
    collectionToShow = collectionEdges;
  }

  collectionToShow = collectionToShow?.map((post) => post.node);

  collectionToShow = collectionToShow?.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  return collectionToShow || [];
};

export const useIsEditing = () => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isInIframe = window.self !== window.top;
      setIsEditing(isInIframe);
    }
  }, []);

  return isEditing;
};

export const ANIMATED_CONTAINER = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const ANIMATED_ITEM = {
  hide: { opacity: "var(--animate-opacity)", y: "var(--animate-y)" },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 75,
      mass: 0.75,
    },
  },
};

export const ANIMATED_CARD = (delay) => ({
  hide: { opacity: "var(--animate-opacity)", y: "var(--animate-y)" },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 75,
      mass: 0.75,
      delay: delay,
    },
  },
});

// Calculate the size of the JSON data
//  const [loadSize, setLoadSize] = useState(null);
// useEffect(() => {
//   if (typeof window !== "undefined") {
//     const jsonData = document.getElementById("__NEXT_DATA__").textContent;
//     console.log("jsonData", jsonData);
//     const jsonSizeInBytes = new Blob([jsonData]).size;
//     const jsonSizeInKB = jsonSizeInBytes / 1024;
//     console.log("JSON size in bytes:", jsonSizeInBytes, jsonSizeInKB.toFixed(2), "KB");
//     setLoadSize(loadSize);
//   }
// }, []);
