import { useEffect } from "react";
import dynamic from "next/dynamic";
import { Navbar, Footer, Tracking, AutoPreview } from "@/components";
import { cn, tinaClient, useIsEditing } from "@/utils";
import "../styles/global.css";

import { Manrope, Playfair_Display } from "next/font/google";

const AdminSidebar = dynamic(() => import("../components/global/AdminSidebar"), { ssr: false });
const SpeedDial = dynamic(() => import("../components/global/SpeedDial"), { ssr: false });

const manrope = Manrope({
  weight: ["300", "400", "500", "600", "700"],
  subsets: ["latin"],
  variable: "--font-manrope",
});

const playfairDisplay = Playfair_Display({
  weight: ["400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
  variable: "--font-playfair-display",
});

const App = ({ Component, pageProps, globalData }) => {
  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isEditing = useIsEditing();

  return (
    <div
      className={cn(
        manrope.variable,
        playfairDisplay.variable,
        "[--animate-y:0px] lg:[--animate-y:40px] [--animate-opacity:1] lg:[--animate-opacity:0]",
        isEditing && "lg:[--animate-y:0px] lg:[--animate-opacity:1]"
      )}
    >
      <Navbar globalData={globalData} />
      <Component {...pageProps} globalData={globalData} />
      <Footer globalData={globalData} />
      <SpeedDial globalData={globalData} />
      <Tracking />
      <AdminSidebar />
      <AutoPreview />
    </div>
  );
};

App.getInitialProps = async () => {
  const treatmentsList = await tinaClient.queries.treatmentsList({
    relativePath: "treatments.mdx",
  });

  const contact = await tinaClient.queries.contact({
    relativePath: "contact.mdx",
  });

  return {
    globalData: {
      ...treatmentsList?.data?.treatments,
      ...contact?.data?.contact,
    },
    revalidate: 60 * 60, // e.g., revalidate every hour
  };
};

export default App;
