import { useState, useEffect } from "react";
import { useRouter } from "next/router";
// import cn from "classnames"
// import axios from "axios"

import {
  FigmaIcon,
  VercelIcon,
  AdminIcon,
  CloseIcon,
  CloudinaryIcon,
  TinaIcon,
  LighthouseIcon,
  GoogleAnalyticsIcon,
  ClinikoIcon,
  DoctifyIcon,
} from "assets";

import { cn, useIsEditing } from "lib/utils";
import { Globe } from "lucide-react";
import { GoogleDriveIcon } from "../../assets";

const AdminSidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const router = useRouter();

  const isEditing = useIsEditing();

  // const [checkedPreview, setCheckedPreview] = useState(false);

  // useEffect(() => {
  //   if (isEditing && !checkedPreview) {
  //     fetch("/api/preview/enter")
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("Preview mode entered:", data);
  //         setCheckedPreview(true);
  //       })
  //       .catch((error) => {
  //         console.error("Error entering preview mode:", error);
  //       });
  //   }
  // }, [isEditing, checkedPreview]);

  if (!isEditing) return null;

  return (
    <>
      <div
        className={cn(
          "admin-dashboard fixed top-6 right-0 bg-[#0084ff] hover:bg-[#0474e4] z-[60] rounded-l-full cursor-pointer h-10 w-[56px] flex justify-center items-center"
        )}
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        {drawerOpen ? (
          <CloseIcon className="w-6 h-6 text-white ml-1" />
        ) : (
          <AdminIcon className="w-7 h-7 text-white ml-1" />
        )}
      </div>
      <div className={cn("fixed top-0 -right-1 h-screen z-base bg-white z-50 shadow-sm border-l border-gray-100 ")}>
        <div
          className={cn(
            "relative h-screen transform flex transition-all duration-300 ease-out overflow-hidden",
            drawerOpen ? "max-w-[450px] z-50" : "max-w-[1px]"
          )}
        >
          <div className="p-8 min-w-[450px] overflow-hidden flex flex-col gap-6 divide-y divide-black/10">
            <div className="flex-[2] space-y-4 overflow-auto">
              <div className="font-semibold text-xl select-none text-black">Resources</div>
              <div className="space-y-3">
                <ResourceLink
                  icon={GoogleAnalyticsIcon}
                  title="Google Analytics"
                  subtitle="Analyze actions taken by your website visitors"
                  href="https://analytics.google.com/analytics/web/#/p457755963/reports/intelligenthome"
                />

                <ResourceLink
                  icon={FigmaIcon}
                  iconClassName="w-9 h-9"
                  title="Designs"
                  subtitle="View the website mockups in Figma"
                  href="https://www.figma.com/design/L5AaOmd7p4edTvyd2Otci6/The-Eye-Unit-(Final)"
                />

                <ResourceLink
                  icon={GoogleDriveIcon}
                  iconClassName="w-9 h-9"
                  title="Google Drive"
                  subtitle="All assets and documents are stored here"
                  href="https://drive.google.com/drive/folders/1NK2U6wzXjm6RqRZZUwfXTFb5z1xXxy1h?usp=sharing"
                />

                <ResourceLink
                  icon={TinaIcon}
                  iconClassName="w-10 h-10"
                  title="CMS"
                  subtitle="TinaCMS where the content is managed"
                  href="https://app.tina.io"
                />

                <ResourceLink
                  icon={Globe}
                  iconClassName="w-10 h-10 text-primary"
                  title="Chia"
                  subtitle="For managing website domain"
                  href="https://chi.uk2.net/home"
                />
                <ResourceLink
                  icon={Globe}
                  iconClassName="w-10 h-10 text-primary"
                  title="Hosting"
                  subtitle="Manage Subscription for Vercel Hosting"
                  href="https://billing.stripe.com/p/login/bIY8yU57EeJacTe5kk"
                />
                <ResourceLink
                  icon={ClinikoIcon}
                  iconClassName="w-10 h-10"
                  title="Cliniko"
                  subtitle="For managing appointments"
                  href="https://bristol-eye-unit.uk2.cliniko.com/"
                />

                <ResourceLink
                  icon={DoctifyIcon}
                  iconClassName="w-10 h-10"
                  title="Doctify"
                  subtitle="For managing reviews"
                  href="https://dashboard.doctify.com/uk/login?redirectUrl=/uk/dashboard/home"
                />
              </div>
            </div>
            <div className="flex-1 pt-6 overflw-hidden flex flex-col gap-4">
              <div className="font-semibold text-lg text-black">Page Details</div>
              {console.log(router.pathname)}
              {!router.pathname.includes("error") && !router.pathname.includes("404") && (
                <details className="h-full">
                  <summary className="border border-gray-100 flex items-center gap-3 px-3 py-3 rounded cursor-pointer hover:bg-gray-100/20">
                    <div className="w-12 h-12 flex items-center justify-center">
                      <LighthouseIcon className="w-10 h-10" />
                    </div>
                    <div className="space-y-0.5">
                      <div className="text-black font-medium font-sans">Page Report</div>
                      <div className="text-gray-500 text-sm">Audit reports run on this page</div>
                    </div>
                  </summary>
                  <iframe
                    src={`/lighthouse/reports/${router.asPath}/lighthouse.html`}
                    width="100%"
                    height="100%"
                    className="border border-gray-100 -mt-1"
                  />
                </details>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <VercelToolbar /> */}
    </>
  );
};

export default AdminSidebar;

const ResourceLink = ({ title, subtitle, icon: Icon, iconClassName = "w-7 h-7", href = "" }) => {
  return (
    <a
      className="border border-gray-300 flex items-center gap-3 px-3 py-3 rounded cursor-pointer hover:bg-gray-100/40"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="w-12 h-12 flex items-center justify-center">
        <Icon className={iconClassName} />
      </div>
      <div className="space-y-0.5">
        <div className="font-semibold text-black">{title}</div>
        <div className="text-gray-500 text-sm">{subtitle}</div>
      </div>
    </a>
  );
};
