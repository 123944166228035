import React from "react";
import { tinaField } from "@/utils";
import { Breadcrumb, Button, Markdown, Image } from "@/components";
import { Arrow, LogoEye } from "../../assets";
import Link from "next/link";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ANIMATED_CONTAINER, ANIMATED_ITEM, cn } from "@/lib/utils";

export default function Header({ variant = "full-image", header, breadCrumb, className, children }) {
  if (variant === "short-centered") {
    const firstTwoWords = header?.title.split(" ").slice(0, 2).join(" ");
    const restOfWords = header?.title.split(" ").slice(2).join(" ");
    return (
      <motion.header
        className="relative"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="absolute -left-24 -top-16 z-0">
          <LogoEye className="w-96 h-96 text-primary text-opacity-5" />
        </div>
        <div className="content py-16 flex flex-col items-center text-center">
          <Breadcrumb label={breadCrumb} animate={true} />
          <motion.h1
            className="h1 text-center !font-normal mt-4 mb-5 max-w-[800px]"
            data-tina-field={tinaField(header, "title")}
            variants={ANIMATED_ITEM}
          >
            <span className="italic font-light mr-3">{firstTwoWords}</span>
            {restOfWords}
          </motion.h1>
          <motion.p className="max-w-[800px]" data-tina-field={tinaField(header, "subtitle")} variants={ANIMATED_ITEM}>
            {header?.subtitle}
          </motion.p>
        </div>
      </motion.header>
    );
  }

  if (variant === "short-left") {
    return (
      <motion.header
        className="relative"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="content py-8 lg:py-16 flex flex-col relative">
          <div className="absolute right-0 lg:right-10 top-12 z-0">
            <LogoEye className="w-24 h-24 text-primary opacity-10 lg:opacity-100" />
          </div>
          <Breadcrumb label={breadCrumb} animate={true} />
          <motion.h1
            className="h1 !font-normal mt-4 mb-5 max-w-[800px]"
            data-tina-field={tinaField(header, "title")}
            variants={ANIMATED_ITEM}
          >
            {header?.title}
          </motion.h1>

          {header?.subtitle && (
            <motion.p
              className="max-w-[620px]"
              data-tina-field={tinaField(header, "subtitle")}
              variants={ANIMATED_ITEM}
            >
              {header?.subtitle}
            </motion.p>
          )}

          {header?.description && (
            <motion.div
              className="max-w-[620px]"
              data-tina-field={tinaField(header, "description")}
              variants={ANIMATED_ITEM}
            >
              <Markdown content={header.description} />
            </motion.div>
          )}
          {children}
        </div>
      </motion.header>
    );
  }

  if (variant === "treatments") {
    return (
      <motion.header
        className="bg-secondary"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="content flex flex-col-reverse lg:flex-row justify-between lg:items-center gap-6 md:gap-12 lg:gap-20 py-8 lg:py-10 ">
          <div className="absolute -left-24 -top-16 z-0">
            <LogoEye className="w-96 h-96 text-secondary-700/30" />
          </div>
          <div className="flex-[2] pt-4 lg:pb-12">
            <Breadcrumb label="Treatments" animate={true} />
            <motion.h1
              className="h1 !font-normal mt-4 mb-5 max-w-[800px]"
              data-tina-field={tinaField(header, "title")}
              variants={ANIMATED_ITEM}
            >
              {header?.title}
            </motion.h1>
            {header?.subtitle && (
              <motion.p
                className="lg:max-w-[620px] mb-8 p-lg"
                data-tina-field={tinaField(header, "subtitle")}
                variants={ANIMATED_ITEM}
              >
                {header?.subtitle}
              </motion.p>
            )}

            <motion.div
              className="mt-8 flex flex-wrap gap-4 flex-col md:flex-row items-stretch "
              variants={ANIMATED_ITEM}
            >
              <Link href="#treatments" className="flex items-center gap-4 font-semibold" aria-label="View Treatments">
                <div className="rounded-full flex items-center justify-center border border-dark/30 p-2">
                  <Arrow className="w-6 h-6" />
                </div>
                View Treatments
              </Link>
            </motion.div>
          </div>
          <motion.div
            className="overflow-hidden rounded-lg  h-[280px] md:h-[320px] w-full max-w-[440px] xl:h-[440px] xl:w-[620px] xl:max-w-none [--animate-y:0px]"
            variants={ANIMATED_ITEM}
            data-tina-field={tinaField(header, "image")}
          >
            <Image src={header?.image} alt={header?.title} className="h-full w-full" width={620} height={440} />
          </motion.div>
        </div>
      </motion.header>
    );
  }

  if (variant === "treatment") {
    return (
      <motion.header
        className="bg-primary-200"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="content flex flex-col-reverse lg:flex-row justify-between lg:items-center gap-6 md:gap-12 lg:gap-20 py-8 lg:py-10 lg:min-h-[600px]">
          <div className="flex-[2] pt-4 lg:pb-12">
            <motion.div className="mb-4 lg:mb-8 flex gap-3" variants={ANIMATED_ITEM}>
              <Button variant="link" asChild className="text-dark/50 hover:text-dark hover:no-underline py-0">
                <Link href="/treatments" replace aria-label="View Treatments">
                  Treatments
                </Link>
              </Button>
              <div className="relative font-semibold before:absolute before:content-[''] before:!w-[1px] before:h-3/4 before:translate-y-[15%] before:bg-primary before:-ml-3 ml-3">
                {breadCrumb}
              </div>
            </motion.div>
            <motion.h1
              className="h1 !font-normal mt-4 mb-5 max-w-[800px]"
              data-tina-field={tinaField(header, "title")}
              variants={ANIMATED_ITEM}
            >
              {header?.title}
            </motion.h1>
            {header?.subtitle && (
              <motion.p
                title={header?.subtitle}
                className="lg:max-w-[620px] mb-8 p-lg line-clamp-6"
                data-tina-field={tinaField(header, "subtitle")}
                variants={ANIMATED_ITEM}
              >
                {header?.subtitle}
              </motion.p>
            )}

            {(header?.button || header?.buttonTwo) && (
              <motion.div
                className="mt-8 flex flex-wrap gap-4 flex-col md:flex-row items-stretch "
                variants={ANIMATED_ITEM}
              >
                {header?.button && (
                  <Button asChild>
                    <Link href={header.button.link} aria-label={header.button.label}>
                      {header.button.label}
                    </Link>
                  </Button>
                )}
                {header?.buttonTwo && (
                  <Button variant="outline" asChild>
                    <Link href={header.buttonTwo.link} aria-label={header.buttonTwo.label}>
                      {header.buttonTwo.label}
                    </Link>
                  </Button>
                )}
              </motion.div>
            )}
          </div>
          <motion.div
            className="overflow-hidden rounded-lg  h-[280px] md:h-[320px] w-full max-w-[440px] xl:h-[440px] xl:w-[620px] xl:max-w-none [--animate-y:0px]"
            variants={ANIMATED_ITEM}
          >
            <Image src={header?.image} alt={header?.title} className="h-full w-full" width={620} height={440} />
          </motion.div>
        </div>
      </motion.header>
    );
  }

  if (variant === "collection") {
    return (
      <motion.header
        className="h-[50vh] min-h-[400px] lg:h-[45vh] bg-cover bg-center relative flex"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
        style={{
          backgroundImage: `url(${header?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 25%",
        }}
      >
        <div className="overlay absolute inset-0 bg-gradient-to-t from-black/80 to-black/40" />
        <div className="flex-1 content relative z-10 flex flex-col justify-end py-8 lg:py-12 gap-3 text-white">
          <Breadcrumb label={breadCrumb} animate={true} />
          <motion.h1
            data-tina-field={tinaField(header, "title")}
            className="text-white h1 max-w-[500px]"
            variants={ANIMATED_ITEM}
          >
            {header?.title}
          </motion.h1>
          <motion.p
            className="p-lg max-w-[620px]"
            data-tina-field={tinaField(header, "subtitle")}
            variants={ANIMATED_ITEM}
          >
            {header?.subtitle}
          </motion.p>
        </div>
      </motion.header>
    );
  }

  if (variant === "collection-item") {
    return (
      <motion.header
        className="relative"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
      >
        <div className="content py-8 lg:pt-16 lg:pb-12 flex flex-col relative border-b border-dark/30">
          <div className="absolute right-0 lg:right-10 top-12 z-0">
            <LogoEye className="w-24 h-24 text-primary opacity-10 lg:opacity-100" />
          </div>
          <Breadcrumb label={breadCrumb} animate={true} />
          <motion.h1
            className="h1 !font-normal mt-4 mb-5 max-w-[800px]"
            data-tina-field={tinaField(header.post, "title")}
            variants={ANIMATED_ITEM}
          >
            {header?.title}
          </motion.h1>
          <motion.div className="flex flex-col lg:flex-row lg:items-end justify-between gap-4" variants={ANIMATED_ITEM}>
            {header?.subtitle && (
              <p className="max-w-[620px] line-clamp-4" data-tina-field={tinaField(header.post, "summary")}>
                {header?.subtitle}
              </p>
            )}
            <div>
              {/* <div>SHARE</div> */}
              <div data-tina-field={tinaField(header.post, "date")}>
                Published on {dayjs(header?.date).format("MMMM DD, YYYY")}
              </div>
            </div>
          </motion.div>
        </div>
      </motion.header>
    );
  }

  if (variant === "home") {
    const firstWords = header?.title.split(" ").slice(0, 1).join(" ");
    const restOfWords = header?.title.split(" ").slice(1).join(" ");

    return (
      <motion.header
        className="h-[50vh] lg:h-[80vh] bg-cover bg-center relative flex"
        initial="hide"
        whileInView="show"
        viewport={{ once: true }}
        variants={ANIMATED_CONTAINER}
        style={{
          backgroundImage: `url(${header?.image})`,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 object-cover object-center w-full h-full z-0"
          poster={header?.image}
        >
          <source src={header?.video} type="video/mp4" />
        </video>
        <div className="overlay absolute inset-0 bg-gradient-to-t from-black/80 via-black/60 to-black/40" />
        <div className="flex-1 content relative z-10 flex flex-col justify-end py-8 lg:py-16 gap-2 lg:gap-4 text-white">
          <motion.h1
            data-tina-field={tinaField(header, "title")}
            className="text-white h0 max-w-[500px]"
            variants={ANIMATED_ITEM}
          >
            <span className="italic font-light mr-3">{firstWords}</span>
            {restOfWords}
          </motion.h1>
          <motion.p
            className="p-lg !leading-relaxed max-w-[620px] line-clamp-6"
            data-tina-field={tinaField(header, "subtitle")}
            variants={ANIMATED_ITEM}
          >
            {header?.subtitle}
          </motion.p>
        </div>
      </motion.header>
    );
  }

  return (
    <motion.header
      className={cn("h-[40vh] lg:h-[50vh] bg-cover bg-center relative flex", className)}
      initial="hide"
      whileInView="show"
      viewport={{ once: true }}
      variants={ANIMATED_CONTAINER}
      style={{
        backgroundImage: `url(${header?.image})`,
      }}
    >
      <div className="overlay absolute inset-0 bg-gradient-to-t from-black/80 to-black/50" />
      <div className="flex-1 content relative z-10 flex flex-col justify-end py-12 gap-4 text-white">
        <Breadcrumb label="About us" animate={true} />
        <motion.h1
          data-tina-field={tinaField(header, "title")}
          className="text-white h1 max-w-[500px]"
          variants={ANIMATED_ITEM}
        >
          {header?.title}
        </motion.h1>
      </div>
    </motion.header>
  );
}
