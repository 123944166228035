import Link from "next/link";
import { Button, Image } from "@/components";
import { ChevronRight } from "lucide-react";
import { cn } from "lib/utils";
import { forwardRef } from "react";

const Card = forwardRef(({ image, title, description, button, href, className, imageSize, draft = false }, ref) => {
  return (
    <Link href={href} className={cn("flex h-full", className)} ref={ref}>
      <div className="relative w-full bg-gray-50 rounded-lg hover:bg-primary-200/75 transition-colors duration-200 overflow-hidden group flex flex-col">
        {draft && (
          <div className="absolute w-full h-full top-64 left-0 z-20 flex justify-center pointer-events-none">
            <div className="text-7xl font-title font-semibold rotate-[35deg] origin-center h-10 text-black/20">
              Draft
            </div>
          </div>
        )}
        <Image
          src={image}
          alt={title}
          className="w-full h-64 rounded-t-lg"
          width={imageSize?.width}
          height={imageSize?.height}
        />
        <div className="flex-1 flex flex-col p-4">
          <div className="h4 mt-2 mb-3 group-hover:text-primary line-clamp-2">{title}</div>
          <p className="line-clamp-3 text-dark/80">{description}</p>
          <div className="flex-1 flex items-end">
            <Button variant="link" className="mt-4" aria-label={button?.label}>
              {button?.label}
              <ChevronRight className="w-5 h-5 ml-1" />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
});

Card.displayName = "Card";

export default Card;
