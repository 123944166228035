import Head from "next/head";
import globalData from "../../content/global/global.json";

const Seo = ({ seo }) => {
  const globalSeo = globalData.seo;

  const fullSeo = {
    metaTitle: seo?.title ? `${seo.title} | ${globalSeo?.title}` : globalSeo?.title,
    metaDescription: seo?.description || globalSeo?.title,
    keywords: seo?.keywords || globalSeo?.keywords,
    metaImage: globalSeo?.image,
  };

  return (
    <Head>
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta property="og:title" content={fullSeo.metaTitle} />
          <meta name="twitter:title" content={fullSeo.metaTitle} />
          <meta property="og:site_name" content={fullSeo.metaTitle} />
        </>
      )}
      {fullSeo.metaDescription && (
        <>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta property="og:description" content={fullSeo.metaDescription} />
          <meta name="twitter:description" content={fullSeo.metaDescription} />
        </>
      )}
      {fullSeo.metaImage && (
        <>
          <meta property="og:image" itemprop="image" content={fullSeo?.metaImage} />
          <meta name="twitter:image" content={fullSeo?.metaImage} />
          <meta name="image" content={fullSeo?.metaImage} />
        </>
      )}
      {fullSeo.article && <meta property="og:type" content="article" />}
      {fullSeo.keywords && <meta property="og:keywords" content={fullSeo.keywords} />}
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:type" content="website" />

      <meta property="og:url" content="https://theeyeunit.co.uk" />
      {/* {fullSeo.canonical && <link rel="canonical" href={fullSeo.canonical} />} */}
    </Head>
  );
};

export default Seo;
