import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Logo } from "assets";
import { Button, Image } from "@/components";

import { motion, useScroll, useMotionValueEvent } from "framer-motion";

import { cn, tinaField } from "@/utils";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuIndicator,
  NavigationMenuViewport,
} from "@/ui/navigation-menu";
import { ChevronRight, Phone } from "lucide-react";
import { useRouter } from "next/router";

const PATIENT_RESOURCES = [
  {
    label: "Articles",
    description: "Explore eye care articles, vision tips, and advanced treatment insights.",
    link: "/blog",
    active: "/blog",
  },
  {
    label: "Patient Learning Center",
    description: "Curated resources of simple guides and expert advice for eye care",
    link: "/resources",
    active: "/resources",
  },
];

const DOCTOR_RESOURCES = [
  {
    label: "Refer Patients",
    description: "Refer a patient to the Eye Unit",
    link: "/refer-patients",
    active: "/refer-patients",
  },
  // {
  //   label: "Report on Patient Cases",
  //   description: "Submit a patient's post-op care notes to us.",
  //   link: "/refer-patients",
  // },
];

const THE_CLINIC = [
  {
    label: "About Us",
    link: "/about",
    active: "/about",
  },
  {
    label: "Our Team",
    link: "/about#team",
  },
  {
    label: "Find Us",
    link: "/contact#locations",
  },
  {
    label: "Contact Us",
    link: "/contact",
  },
];

const LINKS = [
  {
    label: "Treatments",
    link: "/treatments",
    active: "/treatments",
    submenu: ({ treatments }) => {
      const featuredTreatment = treatments?.treatmentList[0]?.treatment;
      const otherTreatments = treatments?.treatmentList.slice(1, 6);

      return (
        <NavigationMenuContent className="!duration-300">
          <ul className="grid gap-6 p-6 md:w-[400px] lg:w-[640px] lg:grid-cols-[1fr_1fr]">
            <li className="">
              <NavigationMenuLink asChild>
                <Link
                  className="flex flex-col justify-start h-full w-full rounded-lg bg-gray-50 hover:bg-primary-200/75 overflow-hidden"
                  href="/treatments/cataract-surgery"
                >
                  <Image
                    src={featuredTreatment?.header?.image}
                    alt={featuredTreatment?.title}
                    className="w-full h-36"
                    width={282}
                    height={144}
                  />
                  <div className="flex-1 p-4">
                    <div className="mb-2 font-title text-xl">{featuredTreatment?.title}</div>
                    <p className="line-clamp-4 text-sm leading-relaxed text-dark/80">
                      {featuredTreatment?.header?.subtitle}
                    </p>
                  </div>
                </Link>
              </NavigationMenuLink>
            </li>
            <div className="flex flex-col">
              <div className="h6 mb-2">Featured Treatments:</div>
              <div className="flex-1">
                {otherTreatments?.map(({ treatment }, index) => (
                  <ListItem
                    key={index}
                    href={`/treatments/${treatment?._sys?.filename}`}
                    title={treatment?.title}
                    className="-ml-2"
                    arrow
                    active={`${treatment?._sys?.filename}`}
                    size="sm"
                  />
                ))}
              </div>
              <div className="flex items-end justify-stretch border-t mt-2 pt-2">
                <Button variant="link" size="sm" className="text-primary flex justify-start" asChild>
                  <NavigationMenuLink asChild>
                    <Link href="/treatments" aria-label="View All Treatments">
                      View All Treatments
                      <ChevronRight className="w-4 h-4 ml-1" />
                    </Link>
                  </NavigationMenuLink>
                </Button>
              </div>
            </div>
          </ul>
        </NavigationMenuContent>
      );
    },
  },
  {
    label: "For Patients",
    link: "/for-patients",
    active: /\/(blog|resources)/,
    submenu: () => {
      return (
        <NavigationMenuContent className="!duration-300">
          <ul className="grid gap-6 p-4 w-[340px] lg:grid-cols-[1fr]">
            <div className="">
              <div className="h6 pb-2 mb-2 border-b">Patient Resources:</div>
              <div className="space-y-2">
                {PATIENT_RESOURCES.map(({ label, link, description, active }, index) => (
                  <ListItem
                    key={index}
                    href={link}
                    title={label}
                    description={description}
                    arrow
                    className="-mx-2 py-2"
                    active={active}
                  />
                ))}
              </div>
            </div>
          </ul>
        </NavigationMenuContent>
      );
    },
  },
  {
    label: "For GPs & Optometrists",
    link: "/refer-patients",
    active: /\/(refer-patients)/,
    submenu: () => {
      return (
        <NavigationMenuContent className="!duration-300">
          <ul className="grid p-4 w-[340px] lg:grid-cols-[1fr]">
            <div className="h6 pb-2 mb-2 border-b">For GPs & Optometrists:</div>
            <div className="space-y-2">
              {DOCTOR_RESOURCES.map(({ label, link, description, active }, index) => (
                <ListItem
                  key={index}
                  href={link}
                  title={label}
                  description={description}
                  arrow
                  className="-mx-2 py-2"
                  active={active}
                />
              ))}
            </div>
          </ul>
        </NavigationMenuContent>
      );
    },
  },
  {
    label: "The Clinic",
    link: "/about",
    active: /\/(about|contact)/,
    submenu: () => {
      return (
        <NavigationMenuContent className="!duration-300">
          <ul className="grid p-4 w-[260px] lg:grid-cols-[1fr]">
            <div className="h6 pb-2 mb-2 border-b">The Clinic:</div>
            <div className="space-y-1">
              {THE_CLINIC.map(({ label, link, active }, index) => (
                <ListItem key={index} href={link} title={label} arrow className="-mx-2 py-2" active={active} />
              ))}
            </div>
          </ul>
        </NavigationMenuContent>
      );
    },
  },
];

const MOBILE_LINKS = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Treatments",
    link: "/treatments",
  },
  {
    label: "About Us",
    link: "/about",
  },
  {
    label: "Articles",
    link: "/blog",
  },
  {
    label: "Patient Learning Center",
    link: "/resources",
  },
  {
    label: "Refer a Patient",
    link: "/refer-patients",
  },
];

const BUTTONS = [
  {
    label: "Contact Us",
    link: "/contact",
    variant: "outlineLight",
  },
  {
    label: "Book Appointment",
    link: "/book-appointment",
    variant: "secondary",
  },
];

export default function Navbar({ globalData }) {
  const treatments = globalData?.treatments;
  const contact = globalData?.contact;

  const [hidden, setHidden] = useState(false);
  const { scrollY } = useScroll();

  const update = () => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 220 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
  };

  useMotionValueEvent(scrollY, "change", () => {
    update();
  });

  return (
    <>
      <div className="bg-primary text-white border-b border-white/30 text-sm py-2 z-50 hidden lg:block">
        <div className="content flex justify-center items-center text-base">
          <Phone className="w-4 h-4 mr-2" />
          Call us now on
          <a href={`tel:${contact?.phone}`} className="ml-2 font-semibold">
            {contact?.phone}
          </a>
        </div>
      </div>
      <motion.nav
        className="bg-primary top-0 w-full z-50 sticky"
        animate={hidden ? "hidden" : "visible"}
        variants={{ hidden: { y: -180 }, visible: { y: 0 } }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <div className="content flex justify-between items-center py-5">
          <div className="-ml-2 lg:ml-0">
            <Link href="/" aria-label="The Eye Unit">
              <Logo className="h-12" />
            </Link>
          </div>
          <DesktopMenu treatments={treatments} />
          <MobileMenu />
        </div>
      </motion.nav>
    </>
  );
}

function DesktopMenu({ treatments }) {
  const location = useRouter();

  function onNavChange() {
    const triggers = document.querySelectorAll('.submenu-trigger[data-state="open"]');
    if (triggers.length === 0) return;

    const firstTrigger = triggers[0];
    const viewports = document.getElementsByClassName("submenu-viewport");

    if (viewports.length > 0) {
      const viewport = viewports[0];

      if (firstTrigger.offsetLeft > 200) {
        viewport.style.left = `${firstTrigger.offsetLeft + 100}px`;
      } else {
        viewport.style.left = `50%`;
      }
    }
  }

  return (
    <div className="hidden xl:flex items-center gap-2 [&>div]:pl-4 [&>div:first-child]:pl-0">
      <div className="">
        <NavigationMenu onValueChange={onNavChange}>
          <NavigationMenuList className="flex gap-3">
            {LINKS.map(({ label, link, active, submenu }, index) => {
              const isActive =
                active instanceof RegExp ? active.test(location.asPath) : location.asPath.includes(active);

              return (
                <NavigationMenuItem key={`nav-item-${index}`}>
                  {submenu ? (
                    <>
                      <NavigationMenuTrigger className={navigationMenuTriggerStyle({ active: isActive })}>
                        {label}
                      </NavigationMenuTrigger>
                      {submenu({ treatments })}
                    </>
                  ) : (
                    <Link href={link} className={navigationMenuTriggerStyle({ active: isActive })} aria-label={label}>
                      {label}
                    </Link>
                  )}
                </NavigationMenuItem>
              );
            })}
            {/* <NavigationMenuIndicator /> */}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <div className="flex gap-4">
        {BUTTONS.map(({ link, label, variant }, index) => (
          <Button key={index} variant={variant} size="sm" asChild>
            <Link href={link} aria-label={label}>
              {label}
            </Link>
          </Button>
        ))}
      </div>
    </div>
  );
}

const ListItem = React.forwardRef(
  ({ className, title, arrow = false, description, children, active, size = "md", ...props }, ref) => {
    const location = useRouter();
    const isActive = active && location.asPath.includes(active);

    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray text-primary",
              isActive ? "!bg-gray/75" : "",
              className
            )}
            aria-label={title}
            {...props}
          >
            <div className="flex items-center justify-between ">
              <div
                className={cn(
                  "text-[15px] font-semibold truncate leading-relaxed w-full max-w-[250px]",
                  size === "sm" && "text-sm"
                )}
              >
                {title}
              </div>
              {arrow && <ChevronRight className="w-4 h-4 ml-1" />}
            </div>
            {description && <p className="text-sm leading-relaxed text-dark/70 mr-4">{description}</p>}
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);

const menu = {
  show: {
    clipPath: "ellipse(200% 150% at 100% 0%)",
    transition: {
      type: "spring",
      stiffness: 40,
      restDelta: 2,
      // delay: 0.2,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  hidden: {
    clipPath: "ellipse(50% 25% at 120% -30%)",
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
    },
  },
};

const linkVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      // duration: 0.04,
      // ease: "easeInOut",
      type: "spring",
      stiffness: 75,
      mass: 0.5,
    },
  },
};

function MobileMenu({ treatments }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add("prevent-scroll");
    } else {
      document.documentElement.classList.remove("prevent-scroll");
    }
    return () => {};
  }, [isOpen]);

  const location = useRouter();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
      document.documentElement.classList.remove("prevent-scroll");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const MotionLink = motion.create(Link);
  const MotionButton = motion.create(Button);
  return (
    <>
      <button onClick={handleClick} className="flex xl:hidden flex-col justify-center items-center scale-125">
        <span
          className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-lg ${
            isOpen ? "rotate-45 translate-y-1" : "-translate-y-[3px]"
          }`}
        ></span>
        <span
          className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-lg my-0.5 ${
            isOpen ? "opacity-0" : "opacity-100"
          }`}
        ></span>
        <span
          className={`bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-lg' ${
            isOpen ? "-rotate-45 -translate-y-1" : "translate-y-[3px]"
          }`}
        ></span>
      </button>
      <motion.div
        className={cn(
          "fixed left-0 z-50 visible w-full overflow-hidden px-6 pt-2 pb-6 mb-12 bg-primary flex flex-col",
          isOpen ? "pointer-events-auto" : "pointer-events-none"
        )}
        style={{ height: "calc(var(--vh, 1vh) * 100 - 80px)", top: 80 }}
        initial="hidden"
        animate={isOpen ? "show" : "hidden"}
        variants={menu}
      >
        {MOBILE_LINKS.map(({ link, label }, index) => (
          <MotionLink
            key={index}
            href={link}
            className="text-white text-lg font-title font-medium py-4 border-b border-white/30 first:border-t hover:bg-primary-400 px-2 -mx-2 flex justify-between items-center"
            variants={linkVariants}
          >
            {label}
            <ChevronRight className="w-6 h-6 lg:h-6 lg:w-6" />
          </MotionLink>
        ))}
        <div className="flex-1 flex flex-col justify-end items-stretch gap-4 -mx-2">
          {BUTTONS.map(({ link, label, variant }, index) => (
            <MotionButton key={index} variants={linkVariants} asChild variant={variant || "outlineLight"}>
              <Link href={link} aria-label={label}>
                {label}
              </Link>
            </MotionButton>
          ))}
        </div>
      </motion.div>
    </>
  );
}
