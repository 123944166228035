import { Section, Markdown } from "@/components";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/ui/accordion";

import { tinaClient, useTina, tinaField } from "@/utils";

export default function FAQs({ faqs }) {
  if (!faqs) return <div className="py-12"></div>;

  return (
    <Section
      wrapperClassName="bg-primary-200"
      className="py-16 lg:py-20 flex flex-col gap-6 md:gap-10 text-center"
      id="FAQs"
    >
      <h2 className="h3">Frequently asked questions</h2>
      <Accordion type="single" collapsible className="w-full max-w-[900px] mx-auto" defaultValue="item-0">
        {faqs?.questions?.map((faq, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger data-tina-field={tinaField(faqs, `questions.${index}.question`)}>
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="markdown" data-tina-field={tinaField(faqs, `questions.${index}.answer`)}>
              <Markdown content={faq?.answer} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Section>
  );
}
