import { Section, Button } from "@/components";
import { tinaField, cn } from "@/utils";

import { LogoEye } from "../../assets";
import Link from "next/link";
import { ANIMATED_ITEM } from "../../lib/utils";
import { motion } from "framer-motion";

export default function CallToAction({ title, subtitle, button, variant, animate = false, ...rest }) {
  const SectionToUse = animate ? motion.div : "div";
  const motionProps = animate
    ? { initial: "hide", whileInView: "show", viewport: { once: true, amount: 0.5 }, variants: ANIMATED_ITEM }
    : {};
  return (
    <Section>
      <SectionToUse
        className={cn(
          "px-8 py-12 lg:p-16 rounded-lg max-w-[980px] mx-auto  relative overflow-hidden",
          variant === "dark" ? "bg-primary text-white" : "bg-primary-200 text-primary"
        )}
        {...motionProps}
      >
        <div className="absolute -top-4 -left-4 -right-4 z-0 flex flex-wrap justify-center gap-6">
          {Array.from({ length: 140 }).map((_, index) => (
            <LogoEye
              key={index}
              className={cn(
                "w-16 h-16",
                variant === "dark" ? "text-white opacity-[0.025]" : "text-primary opacity-[0.035]"
              )}
            />
          ))}
        </div>
        <div className=" relative z-10 flex flex-col items-center">
          <div className="h1 text-center max-w-[720px] !font-medium" data-tina-field={tinaField(rest, "title")}>
            {title}
          </div>
          <p className="p-lg text-center mt-6 mb-8" data-tina-field={tinaField(rest, "subtitle")}>
            {subtitle}
          </p>
          {button?.label && (
            <Button
              variant={variant === "dark" ? "outlineLight" : "default"}
              data-tina-field={tinaField(rest, "button.label")}
              asChild
            >
              <Link href={button?.link || ""} aria-label={button?.label}>
                {button?.label}
              </Link>
            </Button>
          )}
        </div>
      </SectionToUse>
    </Section>
  );
}
