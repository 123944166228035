import { TinaMarkdown } from "tinacms/dist/rich-text";
import { Video } from "@/components";

export default function Markdown({ content }) {
  return (
    <div className="markdown">
      <TinaMarkdown content={content} components={{ Video }} />
    </div>
  );
}
