import React from "react";
import { Button } from "@/components";
import Link from "next/link";

import { Logo } from "assets";
import { Facebook, Instagram, Mail, Phone, Youtube } from "lucide-react";

const NAV_LINKS = [
  {
    title: "Resources",
    links: [
      {
        label: "Articles",
        href: "/blog",
      },
      { label: "Patient Learning Center", href: "/resources" },
      { label: "Refer Patients", href: "/refer-patients" },
      // { label: "Report on Patient Cases", href: "/refer-patients" },
    ],
  },
  {
    title: "The Clinic",
    links: [
      {
        label: "Contact Us",
        href: "/contact",
      },
      {
        label: "Find Us",
        href: "/contact#locations",
      },
      {
        label: "About Us",
        href: "/about",
      },
      {
        label: "Our Team",
        href: "/about#team",
      },
    ],
  },
];

const SOCIAL_ICONS = {
  youtube: <Youtube />,
  facebook: <Facebook className="w-5 h-5" />,
  instagram: <Instagram className="w-5 h-5" />,
};

export default function Footer({ globalData }) {
  const treatments = globalData?.treatments;
  const contact = globalData?.contact;
  const locations = globalData?.locations;

  var date = new Date();
  var year = date.getFullYear();

  let treatmentLinks = treatments?.treatmentList
    ?.map(({ treatment }) => ({
      label: treatment?.title,
      href: `/treatments/${treatment?._sys?.filename}`,
    }))
    .slice(0, 6);

  // TODO: Add view all treaments as the last link
  treatmentLinks?.push({
    label: "View All Treatments",
    href: "/treatments",
  });

  let ALL_NAV_LINKS = [
    {
      title: "Treatments",
      links: treatmentLinks,
    },
    ...NAV_LINKS,
    {
      title: "Our Locations",
      links: locations?.addresses?.map((location) => ({
        label: location?.title,
        href: `/contact#locations`,
      })),
    },
  ];

  return (
    <footer className="bg-primary text-white">
      <div className="content flex flex-col pt-16 pb-8">
        <div className="border-t border-white/50 pt-8 flex flex-col lg:flex-row divide-y lg:divide-y-0 lg:divide-x divide-white/50 gap-12 lg:gap-12 [&>div]:pt-12 lg:[&>div]:pt-0 lg:[&>div]:pl-12 [&>div:first-child]:pl-0 [&>div:first-child]:pt-0">
          <div className="flex flex-col min-w-[250px] gap-8">
            <div className="flex-1 flex justify-center lg:justify-start">
              <Link href="/" aria-label="The Eye Unit">
                <Logo className="h-14" />
              </Link>
            </div>
            <div className="flex flex-col gap-4 max-w-[400px] w-full mx-auto">
              <div className="font-title font-semibold text-center lg:text-left">Get started with us:</div>
              <div className="flex flex-col gap-3">
                <Button variant="linkLight" asChild className="lg:justify-start">
                  <a href={`mailto:${contact?.email}`} aria-label="Email Us">
                    <Mail className="w-4 h-4 mr-2" />
                    {contact?.email}
                  </a>
                </Button>
                <Button variant="linkLight" asChild className="lg:justify-start mb-2">
                  <a href={`tel:${contact?.phone}`} aria-label="Call Us">
                    <Phone className="w-4 h-4 mr-2" />
                    {contact?.phone}
                  </a>
                </Button>
                <Button variant="outlineLight" size="sm" asChild>
                  <Link href="/contact" aria-label="Contact Us">
                    Contact Us
                  </Link>
                </Button>
                <Button variant="secondary" size="sm" asChild>
                  <Link href="/book-appointment" aria-label="Book Appointment">
                    Book Appointment
                  </Link>
                </Button>
              </div>
            </div>
          </div>

          <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-[1fr_1fr_0.75fr_1.25fr] gap-12">
            {ALL_NAV_LINKS.map((nav) => (
              <div key={nav.title} className="flex flex-col items-center lg:items-start gap-4">
                <div className="font-title font-semibold text-lg">{nav.title}</div>
                <div className="w-full flex flex-col items-center lg:items-start gap-3 overflow-hidden">
                  {nav?.links?.map((link) => (
                    <Button
                      key={link.label}
                      variant="linkLight"
                      asChild
                      className="w-full justify-start text-center lg:text-left whitespace-normal line-clamp-1"
                      title={link.label}
                    >
                      <Link href={link.href} className="w-full rounded-none" aria-label={link.label}>
                        {link.label}
                      </Link>
                    </Button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="border-t border-white/50 pt-4 mt-10 flex flex-col md:flex-row justify-between md:items-start gap-6">
          <div className="space-y-3">
            <div className="flex flex-col-reverse md:flex-row gap-8 items-center ">
              <div className="text-sm">© {year} The Eye Unit. All rights reserved.</div>
              <Button variant="linkLight" className="font-normal" asChild size="sm">
                <Link href="/legal/privacy-policy" aria-label="Privacy Policy">
                  Privacy Policy
                </Link>
              </Button>
              <Button variant="linkLight" className="font-normal" asChild size="sm">
                <Link href="/legal/terms-of-service" aria-label="Terms Of Service">
                  Terms Of Service
                </Link>
              </Button>
            </div>
            <div className="text-xs text-center lg:text-left">
              <a
                className="inline-flex items-center transition-all duration-300 whitespace-nowrap font-semibold  group text-white/80 hover:text-white after:bg-white text-xs"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.shaunpearce.ie"
                aria-label="Website by: Shaun Pearce"
              >
                Website by: Shaun Pearce
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2">
            {/* {socials &&
              Object.keys(socials)?.map((key) => {
                if (!SOCIAL_ICONS[key] || !socials[key]) return null;
                return (
                  <a
                    key={key}
                    href={socials[key]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white [&>svg]:stroke-[1.5px] hover:text-white/80"
                    aria-label={`${key} Link`}
                  >
                    {SOCIAL_ICONS[key]}
                  </a>
                );
              })} */}
          </div>
        </div>
      </div>
    </footer>
  );
}
