import { Seo } from "@/components";

export default function Page({ seo, ...props }) {
  return (
    <>
      <Seo seo={seo} />
      <main {...props}></main>
    </>
  );
}
