import { cn, ANIMATED_ITEM } from "@/utils";
import { motion } from "framer-motion";

export default function Breadcrumb({ label, size = "md", animate = false, ...rest }) {
  const Element = animate ? motion.div : "div";

  return (
    <Element className="text-current flex items-center gap-2" variants={ANIMATED_ITEM} {...rest}>
      <div className="w-2 h-2 border-2 border-current rounded-full" />
      <div className={cn("text-sm md:text-base uppercase font-semibold", size === "sm" && "!text-sm text-primary-400")}>
        {label}
      </div>
    </Element>
  );
}
