import { useEffect } from "react";
import { useRouter } from "next/router";
import { useIsEditing } from "../../lib/utils";

function AutoPreview() {
  const router = useRouter();
  const isEditing = useIsEditing();

  useEffect(() => {
    const enterPreviewMode = async () => {
      const isInTinaAdmin = isEditing;
      const isPreview = router.isPreview;

      if (isInTinaAdmin && !isPreview) {
        try {
          const tinaAuth = localStorage.getItem("tinacms-auth");
          if (tinaAuth) {
            const { id_token } = JSON.parse(tinaAuth);
            if (id_token) {
              const currentPath = window.location.pathname;
              window.location.href = `/api/preview/enter?token=${id_token}&slug=${currentPath}`;
            }
          }
        } catch (error) {
          console.error("Error entering preview mode:", error);
        }
      }
    };

    if (isEditing && typeof window !== "undefined") {
      enterPreviewMode();
    }
  }, [router.isPreview, isEditing]);

  return null; // This component doesn't render anything
}

export default AutoPreview;
