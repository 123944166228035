import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown, CircleMinus, Minus, Plus } from "lucide-react";

import { cn } from "/lib/utils";

const Accordion = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Root ref={ref} className={cn("w-full", className)} {...props} />
));

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("border-b border-dark/30", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex gap-4 flex-1 items-center justify-between py-6 font-medium transition-all [&[data-state=open]>svg]:rotate-180 [&[data-state=closed]>div>div.opened]:opacity-0 [&[data-state=open]>div>div.closed]:opacity-0",
        "h5 text-primary group text-left",
        className
      )}
      {...props}
    >
      {children}
      <div className="relative w-10 flex justify-end -mt-7">
        <div className="w-7 h-7 bg-primary text-white absolute duration-200 opened transition-opacity rounded-full flex justify-center items-center">
          <Minus className="h-5 w-5" />
        </div>
        <div className="w-7 h-7 bg-primary-200 text-primary group-hover:bg-primary group-hover:text-white transition-all absolute duration-200 closed rounded-full flex justify-center items-center ">
          <Plus className="h-5 w-5 shrink-0 duration-200 closed absolute transition-opacity" />
        </div>
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm text-left transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-8 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
