import { useState } from "react";
import { Section, Button } from "@/components";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/ui/carousel";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/ui/dialog";
import { motion } from "framer-motion";
import { Quote } from "lucide-react";

import { ANIMATED_CONTAINER, ANIMATED_ITEM } from "@/utils";

export default function Reviews({ reviews }) {
  const reviewsToShow = reviews.filter((review) => review.text.length > 0).filter((review) => review.score >= 5);

  const [selectedReview, setSelectedReview] = useState(null);
  return (
    <Section wrapperClassName="bg-primary-200" className="py-12 md:py-20 flex flex-col gap-8">
      <motion.hgroup
        className="flex flex-col items-center gap-4 max-w-[600px] mx-auto"
        initial="hide"
        whileInView="show"
        viewport={{ once: true, amount: 0.5 }}
        variants={ANIMATED_CONTAINER}
      >
        <motion.h2 className="h2 text-center" variants={ANIMATED_ITEM}>
          What our patients say
        </motion.h2>
        <motion.p className="text-center opacity-80" variants={ANIMATED_ITEM}>
          Discover the life-changing experiences of our satisfied patients, showcasing our commitment to exceptional eye
          care and transformative results.
        </motion.p>
      </motion.hgroup>

      <Carousel
        className="w-full space-y-8 lg:space-y-0"
        opts={{
          loop: true,
        }}
      >
        <motion.div initial="hide" whileInView="show" viewport={{ once: true, amount: 0.5 }} variants={ANIMATED_ITEM}>
          <CarouselContent className="flex items-center">
            {reviewsToShow.map((review, index) => (
              <CarouselItem key={index} className="">
                <div className="p-1 flex flex-col items-center justify-center gap-2 md:gap-4 lg:gap-8 lg:-ml-6">
                  <div className="flex flex-col items-center">
                    <p className="h3 !font-light text-center max-w-[900px] italic mx-auto line-clamp-4">
                      "{review.text}"
                    </p>
                    <Button variant="link" onClick={() => setSelectedReview(index)} aria-label="Read More">
                      Read more
                    </Button>
                  </div>
                  <a
                    className="capitalize font-semibold flex items-center gap-2"
                    href={review?.review_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{review?.origin}</span>
                    {review?.user_name && (
                      <>
                        <span className="w-2 h-0.5 bg-dark"></span>
                        <span>{review?.user_name}</span>
                      </>
                    )}
                  </a>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </motion.div>
        <div className="relative lg:static flex gap-4 justify-center">
          <CarouselPrevious className="relative lg:absolute lg:-mt-24 translate-y-0" />
          <CarouselNext className="relative lg:absolute lg:-mt-24 translate-y-0" />
        </div>
      </Carousel>

      <Dialog open={selectedReview !== null} onOpenChange={() => setSelectedReview(null)}>
        <DialogContent className="min-h-[300px] max-w-3xl max-h-[80svh] overflow-auto flex flex-col">
          <DialogHeader>
            <DialogTitle className="h3 font-medium">Review by {reviewsToShow[selectedReview]?.user_name}</DialogTitle>
          </DialogHeader>
          <div className="flex-1 gap-6 flex flex-col items-center">
            <Quote className="absolute top-4 left-4 -scale-x-100 h-32 w-32 text-primary text-opacity-5" />
            <p className="italic p-lg text-dark/70">{reviewsToShow[selectedReview]?.text}</p>
            {/* <div className="capitalize font-semibold flex items-center gap-2">
              <span>{reviewsToShow[selectedReview]?.origin}</span>
              <span className="w-2 h-0.5 bg-dark"></span>
              <span>{reviewsToShow[selectedReview]?.user_name}</span>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </Section>
  );
}
